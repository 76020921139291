import * as d3 from 'd3';
import { Fragment, useEffect, useState } from 'react';
import { MetricProperty } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { defaultExpanded } from '@/organisms/charts';
import { filterVisibleChartData } from '@/organisms/charts';
import { isPercentageMetric } from '@/organisms/charts';
import { ChartRowType } from '@/shared/enums';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';
import HorizontalRow from './HorizontalRow';

const ITEM_HEIGHT = 34;
const PADDING = 8;

const HorizontalBarChart = ({
  alwaysExpanded,
  height,
  initialData,
  id,
  labelWidthPercentage = 0.4,
  metric = EntityMetric.PERCENTAGE,
  metricTotals,
  onNavigate,
  relativeScale = true,
  title = 'Activity description',
  width,
}) => {
  const [expandMap, setExpandMap] = useState(defaultExpanded(alwaysExpanded));
  const [data, setData] = useState([]);
  const labelWidth = Math.abs(width * labelWidthPercentage);
  const chartWidth = width - labelWidth - PADDING * 2;
  const chartXPosition = labelWidth + PADDING;
  const [hoverRow, setHoverRow] = useState();
  const percentageMetric = isPercentageMetric(metric);
  const scaleMetric =
    metric === EntityMetric.RELATIVE_PERCENTAGE ? EntityMetric.HOURS : metric;
  const metricLabel = percentageMetric
    ? 'Hours %'
    : MetricProperty[metric]?.label.plural;

  const dataHeight = data.length * ITEM_HEIGHT;

  // The row click handles the expand and collapse functions of the chart.
  const handleRowClick = (row) => {
    const updatedMap = new Map(expandMap);
    if (row.expanded) {
      // To collapse the selected row and all of it's children we do a partial
      // string match on the id and remove all entries.
      Array.from(expandMap.entries()).forEach(([expandedId]) => {
        if (expandedId.includes(row.id)) {
          updatedMap.delete(expandedId);
        }
      });
    } else {
      updatedMap.set(row.id, 0);
    }

    const filtered = filterVisibleChartData(initialData, updatedMap);

    setExpandMap(filtered.expandMap);
    setData(filtered.data);
  };

  const handleRowHover = (row, show) => {
    setHoverRow(show ? row.id : null);
  };

  // This useEffect handles new data from upstream.
  useEffect(() => {
    if (!initialData) {
      return;
    }

    const expandedMap = alwaysExpanded
      ? defaultExpanded(alwaysExpanded)
      : expandMap;

    const filteredData = filterVisibleChartData(initialData, expandedMap);

    setData(filteredData.data);
    setExpandMap(filteredData.expandMap);
  }, [JSON.stringify(initialData)]);

  // The xScale function is used for calculating bar widths.
  const xScale = !width
    ? null
    : d3
        .scaleLinear()
        .domain([
          0,
          metric === 'percentage' && !relativeScale
            ? 100
            : d3.max(data, (d) => {
                return d.data?.[scaleMetric];
              }),
        ])
        .range([0, chartWidth - PADDING * 2]);

  // The yScale function is used for calculating y axis coordinates for the
  // labels and the bars.
  const yScale = !height
    ? null
    : d3.scaleBand(
        data.map(({ id }) => {
          return id;
        }),
        [0, dataHeight]
      );

  useEffect(() => {
    if (!width || !height) {
      return;
    }

    const paddedHeight = dataHeight + PADDING;

    const updatedHeight =
      height > paddedHeight ? height : paddedHeight + ITEM_HEIGHT;

    // Update the height of the svg so we can scroll the list of chart bars
    // vertically.
    d3.select(`#${id}`)
      .attr('height', updatedHeight)
      .attr('viewBox', `0 0 ${width} ${updatedHeight}`);
  }, [width, height, JSON.stringify(data), metric]);

  return (
    xScale &&
    yScale && (
      <g className="horizontal-bar-chart">
        <>
          {data.map((row) => (
            <rect
              key={`background-${row.id}`}
              className="row-background"
              x={0}
              y={yScale(row.id)}
              width={width}
              height={ITEM_HEIGHT}
              fill={
                row.id === hoverRow ? brand.lightBlue.tints[4] : 'transparent'
              }
            />
          ))}
          {data.map((row) => (
            <Fragment key={`separator-${row.id}`}>
              {row.type === ChartRowType.TITLE && (
                <foreignObject
                  key={`title-${row.id}`}
                  className="row-title"
                  x={0}
                  y={yScale(row.id)}
                  width={width}
                  height={ITEM_HEIGHT}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    height={ITEM_HEIGHT}
                    width="100%"
                    pr={1}
                    sx={{
                      backgroundColor: brand.lightBlue.tints[1],
                      boxShadow: `inset 0 -1px 0 ${brand.lightBlue.tints[4]}`,
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      width={labelWidth - ITEM_HEIGHT - PADDING}
                      alignItems="center"
                    >
                      <Heading
                        variant="h6"
                        overrideStyles={{
                          display: 'block',
                          textAlign: 'right',
                          fontWeight: 600,
                        }}
                      >
                        {title}
                      </Heading>
                    </Stack>
                    <Stack
                      direction="row"
                      ml={`${ITEM_HEIGHT + PADDING * 2}px`}
                      justifyContent="flex-start"
                    >
                      <Heading
                        variant="h6"
                        overrideStyles={{
                          fontWeight: 600,
                        }}
                      >
                        {metricLabel}
                      </Heading>
                    </Stack>
                  </Stack>
                </foreignObject>
              )}
              {row.type !== ChartRowType.SEPARATOR &&
                row.type !== ChartRowType.TITLE && (
                  <>
                    <HorizontalRow
                      key={row.id}
                      chartXPosition={chartXPosition}
                      expanded={expandMap}
                      onNavigate={
                        row.type === ChartRowType.PERSON && onNavigate
                          ? onNavigate
                          : null
                      }
                      metric={metric}
                      metricTotals={metricTotals}
                      onClick={row.hasChildren ? handleRowClick : null}
                      onHover={handleRowHover}
                      isHover={hoverRow === row.id}
                      isPercentageMetric={percentageMetric}
                      row={row}
                      labelWidth={labelWidth}
                      width={width}
                      height={ITEM_HEIGHT}
                      yScale={yScale}
                      xScale={xScale}
                    />
                  </>
                )}
            </Fragment>
          ))}
        </>
      </g>
    )
  );
};

export default HorizontalBarChart;
