import { isPercentageMetric } from '@/organisms/charts';
import { ChartRowType, EntityPluralName } from '@/shared/enums';
import { EntityType } from '@/shared/enums';
import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';

const entityPercentageSort = (a, b, sortType) => {
  const aHours = a.metrics[EntityMetric.HOURS];
  const bHours = b.metrics[EntityMetric.HOURS];

  if (a.type === EntityType.ROLE) {
    const aValue = percent(aHours, a.metrics.roleHours);
    const bValue = percent(bHours, b.metrics.roleHours);

    return sortType === 'ASC' ? aValue - bValue : bValue - aValue;
  }

  if (a.type === EntityType.GROUP) {
    const aValue = percent(aHours, a.metrics.groupHours);
    const bValue = percent(bHours, b.metrics.groupHours);

    return sortType === 'ASC' ? aValue - bValue : bValue - aValue;
  }

  return sortType === 'ASC' ? aHours - bHours : bHours - aHours;
};

const getEntityPercentage = (metrics, parentMetrics, entityType) => {
  if (entityType === EntityType.ROLE || entityType === EntityType.PERSON) {
    return percent(metrics.hours, metrics.roleHours, 2);
  }
  if (entityType === EntityType.GROUP) {
    return percent(metrics.hours, metrics.groupHours, 2);
  }

  return metrics[EntityMetric.PERCENTAGE];
};

const addEntityRows = ({
  chartRows,
  entity,
  excludeSeparators,
  includeStack,
  id,
  metrics,
  parentRow,
  stackIndex,
  sort,
  sortMetric,
}) => {
  const { props, type } = entity;
  const childEntity = entity?.childEntity;
  const isEntityPercentageSort = sort.metric === EntityMetric.ENTITY_PERCENTAGE;

  const percentage = percent(entity.metrics.hours, metrics.hours, 2);
  const groupPercentage = percent(
    entity.metrics.hours,
    entity.metrics.groupHours,
    2
  );
  const ids = id.split(':');
  let updatedIndex = stackIndex;

  const rowMetrics = {
    ...entity.metrics,
    percentage,
  };

  const entityPercentage = getEntityPercentage(
    rowMetrics,
    parentRow?.data,
    entity.type
  );
  // We collect the parent metrics to be used for calculating relative data
  // in charts.
  const parentMetrics = parentRow?.data;
  const relativePercentage = parentMetrics
    ? percent(rowMetrics.hours, parentMetrics.hours, 2)
    : rowMetrics.percentage;

  const rowData = {
    data: {
      ...rowMetrics,
      [EntityMetric.RELATIVE_PERCENTAGE]: relativePercentage,
      [EntityMetric.ENTITY_PERCENTAGE]: entityPercentage,
    },
    parentData: parentRow?.data
      ? {
          ...parentMetrics,
        }
      : null,
    parentType: parentRow?.type,
    id,
    label: props?.name ?? props?.title ?? props.description,
    props,
    type,
    hasChildren: Boolean(childEntity),
  };

  if (includeStack && ids.length === 1) {
    rowData.stackData = [];
    updatedIndex = chartRows.length;
  }

  if (includeStack && ids.length === 2) {
    rowData.isStackChild = true;
    chartRows[stackIndex].stackData.push(rowData);
  }

  chartRows.push(rowData);

  if (childEntity) {
    [...entity[childEntity].entries()]
      .sort(([, a], [, b]) => {
        if (isEntityPercentageSort) {
          return entityPercentageSort(a, b, sort.type);
        }

        if (sort.type === 'ASC') {
          return a.metrics[sortMetric] - b.metrics[sortMetric];
        }

        return b.metrics[sortMetric] - a.metrics[sortMetric];
      })
      .forEach(([childId, childEntity], index) => {
        addEntityRows({
          chartRows,
          entity: childEntity,
          excludeSeparators,
          id: `${id}:${childId}`,
          includeStack,
          parentRow: rowData,
          stackIndex: updatedIndex,
          metrics,
          sort,
          sortMetric,
        });
      });
  }
};

/**
 * Takes an aggregated list of activities and converts them into rows of data.
 *
 * @param { Map } activities
 * @param { Object } metrics
 * @param { Object } filter
 *
 * @return { Array }
 */
export default function entityChart({
  entities,
  excludeSeparators,
  includeStack,
  sort = { metric: 'hours', type: 'DESC' },
}) {
  const { order, metrics } = entities;
  const isEntityPercentageSort = sort.metric === EntityMetric.ENTITY_PERCENTAGE;
  const sortMetric = isPercentageMetric(sort.metric)
    ? EntityMetric.HOURS
    : sort.metric;

  if (!entities || !order.length) {
    return [];
  }

  // Set the title.
  const chartRows = excludeSeparators
    ? []
    : [
        {
          id: `${order[0]}:TITLE`,
          type: ChartRowType.TITLE,
          entity: order[0],
          label: EntityPluralName[order[0]],
        },
      ];

  [...entities[order[0]].entries()]
    .sort(([, a], [, b]) => {
      // Short circuit if we're sorting layers.
      if (a?.props?.layerNumber) {
        return a.props.layerNumber - b.props.layerNumber;
      }

      if (isEntityPercentageSort) {
        return entityPercentageSort(a, b, sort.type);
      }

      if (sort.type === 'ASC') {
        return a.metrics[sortMetric] - b.metrics[sortMetric];
      }

      return b.metrics[sortMetric] - a.metrics[sortMetric];
    })
    .forEach(([id, entity]) => {
      addEntityRows({
        chartRows,
        entity,
        excludeSeparators,
        id,
        includeStack,
        metrics,
        sort,
        sortMetric,
      });
    });

  return chartRows;
}
